<template>
  <main class="kb-main" id="kb-myclass-notice">
    <lxp-main-header :showBreadcrumb="true" :show-title="true" title="공지사항">
<!--      <template v-slot:action>-->
<!--        <div class="header-dropdown">-->
<!--          <div class="dropdown" :class="{ 'is-active' : isCategoryActive }" @click="isCategoryActive = !isCategoryActive">-->
<!--            <button class="dropdown-btn kb-btn kb-btn-outline"><strong class="text">{{ cateNm }}</strong><i class="icon-arrow"></i>-->
<!--            </button>-->
<!--            <div class="dropdown-target">-->
<!--              <div class="dropdown-box">-->
<!--                <ul class="dropdown-option-list">-->
<!--                  <li v-for="(item) in ntcCateCdDcd" :key="item.cd" class="dropdown-option-item">-->
<!--                    <a href="javascript:" class="dropdown-option-link" @click="selectCate(item.cd)"><strong class="dropdown-option-text">{{ item.cdNm }}</strong></a>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </template>-->
    </lxp-main-header>
    <!-- main-content -->
    <div class="main-content min-component">
      <div class="search-container">
        <div class="list-top">
          <div class="top-column ps-6">
            <p class="title text-muted">총 {{ paging.totalCount }}건</p>
          </div>
          <div class="top-column">
            <div class="kb-form-search">
              <div class="kb-form-search-field">
                <input type="text" class="kb-form-search-input" placeholder="공지사항 검색"
                       v-model="search.ntcTitle"
                       @keyup.enter="clickSearch"
                />
                <button class="kb-form-search-btn" @click="clickSearch"><i class="icon-search">검색</i></button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="items && items.length === 0" class="search-result">
          <div class="result-empty">
            <img src="../../../assets/lxp/images/common/img_empty.png">
            <p class="text">검색결과가 없어요</p>
          </div>
        </div>

        <div class="board-list-container">
          <ul class="board-list">
            <li v-for="(item, idx) in items" class="board-list-item" :key="idx">
              <article class="board-row">
                <router-link :to="{path: '/help/notice/'+item.comNtcSn}" class="board-link"></router-link>
                <div v-if="item.topFixYn === 'Y'" class="board-column column-index"><em class="text text-gold">필독</em></div>
                <div v-else class="board-column column-index">{{idx+1+(paging.pageSize*(paging.pageNo-1))}}</div>
                <div class="board-column column-category"><span class="text text-muted">{{ item.expsrLocNm }}</span></div>
                <div v-if="item.ntcFileAtchYn === 'Y'" class="board-column column-file"><a href="javascript:" class="file-link"><i class="icon-file"></i></a></div>
                <div class="board-column column-title">
                  <span class="title">{{item.ntcTitle}}</span>
                  <i v-if="item.newNotice === 'Y'" class="icon-new"></i>
                </div>
                <div class="board-column column-date"><span class="text">{{ getDateFormat('yyyy-MM-dd', item.regDt)}}</span></div>
                <div class="board-column column-hit"><span class="text">{{item.inqCnt}} 조회</span></div>
              </article>
            </li>
          </ul>
         <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>

import LxpMainHeader from '@/components/common/LxpMainHeader';
import {computed, onBeforeMount, onMounted, reactive, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {
  getCdNm,
  getItems,
  getPaging,
  initPaging,
  initProgressBar,
  lengthCheck,
  setParamsByQueryString,
  timestampToDateFormat,
} from '@/assets/js/util';
import {ACT_GET_HELP_NOTICE_LIST} from '@/store/modules/help/help';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {expsrLocCds} from "@/assets/js/modules/help/help-common";

export default {
  name: 'HelpNotice',
  components: {CommonPaginationFront, LxpMainHeader},
  setup(){
    const isCategoryActive = ref(false);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const paging = ref(initPaging(route));
    const items = ref([{}]);
    const search = reactive({
      ntcTitle:'',
      ntcCateCdDcd: ''
    });

    const ntcCateCdDcd = computed(() => store.state.code.codes.filter(code => code.cd.substring(4) !== '000' && code.cd.indexOf('2022') === 0));

    const cateNm = computed(() => {
      if (search.ntcCateCdDcd && search.ntcCateCdDcd.length > 0) return getCdNm(store, search.ntcCateCdDcd);
      return '카테고리';
    });

    const nowDt = computed(() => timestampToDateFormat(new Date(), 'yyyy-MM-dd'));
    const getHelpNoticeList = () => {
      store.dispatch(`help/${ACT_GET_HELP_NOTICE_LIST}`, {
        ntcTitle: search.ntcTitle,
        ntcCateCdDcd: search.ntcCateCdDcd,
        expsrLocCd: expsrLocCds.EXPSR_LOC_CD_MAIN, // helpdesk공지사항은 연수시스템만 노출
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res);
          paging.value = getPaging(res);
          // 새 게시물인지 날짜로 비교
          items.value.map((x) => {
            let diff = new Date(nowDt.value) - new Date(timestampToDateFormat(x.regDt, 'yyyy-MM-dd'));
            let month = new Date(diff).getMonth() > 0 ? new Date(diff).getMonth() * 30 : 0;
            let date = new Date(diff).getDate();
            if(month + date <= 7) x.newNotice = 'Y';
            else x.newNotice = 'N';
          })
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    };

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      if(search.ntcTitle) query.ntcTitle = search.ntcTitle;
      if(search.ntcCateCdDcd) query.ntcCateCdDcd = search.ntcCateCdDcd;
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(1, 10);
    };

    const selectCate = (dcd) => {
      if (dcd === '2022001') dcd =  '';
      search.ntcCateCdDcd = dcd;
      pagingFunc(1, 10);
    };

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    watch(() => route.query, () => {
      setParamsByQueryString(route, 'HelpNotice', paging, search, true);
      getHelpNoticeList();
      initProgressBar();
    });

    onBeforeMount(() => {
      setParamsByQueryString(route, 'HelpNotice', paging, search, true);
    });

    onMounted(() => {
      getHelpNoticeList();
    });

    return{
      isCategoryActive,
      items,
      paging,
      search,

      pagingFunc,
      clickSearch,
      getDateFormat,

      ntcCateCdDcd,
      selectCate,
      cateNm
    }

  }
}
</script>